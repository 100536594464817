<template>
  <SearchRedirect/>
</template>

<script>
import SearchRedirect from '@/views/search/SearchRedirect.vue'

export default {
  name: 'Search',
  components: {
    SearchRedirect,
  },
}
</script>

<style scoped>

</style>
